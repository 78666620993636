import React from 'react';
import { Container, Row, Col } from 'react-grid-system';



function Footer() {
    return (
        <div className="footer">
            <h1 className="brand">
                16<br />
                <span>04</span>
            </h1>

            <h3>
                all copyrights reserved - 1604 intelligence, 2020
            </h3>

        </div>
    );
}

export default Footer;
